<template>
    <b-container>
        <h3>TUit Workshops</h3>
        <div class="headerlogo">
            <img class="headerimage" src="../assets/img/logotuit.png" />
        </div>
        <div class="containerMiddle ">
            <div class="row vertical-center-row">
                <div class="text-center col-md col-md-offset-4">
                    <div id="int-header-logo">
                        <div class="logo">
                            <a
                                href="https://www.tugraz.at"
                                title="TU Graz Home"
                                target="_self"
                            >
                                <svg
                                    id="int-header-logo-img"
                                    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xml:space="preserve"
                                    height="51.862"
                                    width="141.1"
                                    version="1.1"
                                    xmlns:cc="http://creativecommons.org/ns#"
                                    xmlns:dc="http://purl.org/dc/elements/1.1/"
                                    viewBox="0 0 141.10001 51.862499"
                                >
                                    <g
                                        transform="matrix(1.25 0 0 -1.25 0 51.862)"
                                    >
                                        <g transform="scale(.1)">
                                            <path
                                                style="fill:#e4154b"
                                                d="m0 103.73h207.45v207.46l-207.45 0.01v-207.47z"
                                            ></path>
                                            <path
                                                style="fill:#e4154b"
                                                d="m228.19 103.73h207.46v207.46h-207.46v-207.46z"
                                            ></path>
                                            <path
                                                style="fill:#e4154b"
                                                d="m456.41 103.73h207.44v207.46h-207.44v-207.46z"
                                            ></path>
                                            <path
                                                style="fill:#e4154b"
                                                d="m103.72 0h207.47v207.46h-207.47v-207.46z"
                                            ></path>
                                            <path
                                                style="fill:#e4154b"
                                                d="m352.68 207.46h207.44v207.46h-207.44v-207.46z"
                                            ></path>
                                            <path
                                                style="fill:#231f20"
                                                d="m751.04 277.91h-66.426v33.195h171.19v-33.195h-66.407v-173.73h-38.359v173.73"
                                            ></path>
                                            <path
                                                style="fill:#231f20"
                                                d="m1048.3 180.22c0-12.461-2.25-23.711-6.72-33.75-4.5-10.039-10.61-18.555-18.36-25.567-7.76-7.031-16.9-12.421-27.503-16.21-10.605-3.809-22.109-5.7036-34.551-5.7036-12.422 0-23.945 1.8946-34.551 5.7036-10.605 3.789-19.824 9.179-27.656 16.21-7.851 7.012-13.984 15.528-18.34 25.567-4.394 10.039-6.582 21.289-6.582 33.75v130.89h38.379v-129.59c0-5.039 0.801-10.351 2.442-15.898 1.64-5.547 4.336-10.664 8.125-15.332s8.789-8.516 15.039-11.523c6.211-3.008 13.926-4.512 23.144-4.512 9.199 0 16.914 1.504 23.145 4.512 6.23 3.007 11.25 6.855 15.039 11.523 3.77 4.668 6.48 9.785 8.12 15.332 1.63 5.547 2.45 10.859 2.45 15.898v129.59h38.38v-130.89"
                                            ></path>
                                            <path
                                                style="fill:#231f20"
                                                d="m832.56 75.664c-7.597 3.2812-17.46 4.8632-25.332 4.8632-22.929 0-35.605-14.434-35.605-33.184 0-18.613 12.383-32.637 33.34-32.637 5.351 0 9.59 0.5274 12.969 1.3086v23.867h-20.84v14.414h39.687v-49.297c-10.41-2.6172-21.25-4.707-31.816-4.707-31.797 0-53.906 14.805-53.906 45.742 0 31.348 20.566 48.906 53.906 48.906 11.406 0 20.41-1.4453 28.867-3.8086l-1.27-15.469"
                                            ></path>
                                            <path
                                                style="fill:#231f20"
                                                d="m856.2 69.375h16.758v-15.332h0.293c0.84 6.289 8.574 16.914 19.824 16.914 1.836 0 3.828 0 5.782-0.5273v-17.715c-1.68 0.918-5.059 1.4454-8.457 1.4454-15.333 0-15.333-17.832-15.333-27.52v-24.785h-18.867v67.52"
                                            ></path>
                                            <path
                                                style="fill:#231f20"
                                                d="m913.75 65.84c7.324 3.1446 17.187 5.1172 25.215 5.1172 22.09 0 31.23-8.5351 31.23-28.457v-8.6523c0-6.8165 0.156-11.934 0.293-16.914 0.137-5.1172 0.41-9.8242 0.84-15.078h-16.602c-0.703 3.5352-0.703 8.0078-0.839 10.098h-0.293c-4.36-7.4618-13.81-11.661-22.38-11.661-12.793 0-25.332 7.207-25.332 20.059 0 10.078 5.195 15.976 12.383 19.258 7.187 3.2812 16.464 3.9453 24.355 3.9453h10.41c0 10.879-5.195 14.551-16.328 14.551-8.008 0-16.035-2.8907-22.363-7.3438l-0.586 15.078zm22.11-52.715c5.782 0 10.274 2.3633 13.223 6.0352 3.105 3.8086 3.945 8.6523 3.945 13.906h-8.164c-8.437 0-20.957-1.3086-20.957-11.68 0-5.7617 5.195-8.2617 11.953-8.2617"
                                            ></path>
                                            <path
                                                style="fill:#231f20"
                                                d="m985.69 69.375h57.422v-14.414l-36.04-39.473h37.31v-13.633h-60.235v14.297l36.715 39.59h-35.172v13.633"
                                            ></path>
                                            <path
                                                style="fill:#e4154b"
                                                d="m1059.6 0h69.102v69.121h-69.102v-69.121z"
                                            ></path>
                                        </g>
                                    </g>
                                </svg>
                            </a>
                        </div>
                        <ul class="circle-container">
                            <li
                                @click="showModal('CO2')"
                                v-b-tooltip.hover.top="'CO2'"
                            >
                                <img
                                    src="../assets/img/co2-modell.jpg"
                                    :class="checkWorkshop('CO2')"
                                />
                            </li>
                            <li
                                @click="showModal('BBCmicrobits')"
                                v-b-tooltip.hover.top="'BBCmicrobits'"
                            >
                                <img
                                    src="../assets/img/bbcmicrobits-modell.jpg"
                                    :class="checkWorkshop('BBCmicrobits')"
                                />
                            </li>
                            <li
                                @click="showModal('Elektrotechnik')"
                                v-b-tooltip.hover.top="'Elektrotechnik'"
                            >
                                <img
                                    src="../assets/img/elektrotechnik.jpg"
                                    :class="checkWorkshop('Elektrotechnik')"
                                />
                            </li>
                            <li
                                @click="showModal('FabLab')"
                                v-b-tooltip.hover.top="'FabLab'"
                            >
                                <img
                                    src="../assets/img/fablab.jpg"
                                    :class="checkWorkshop('FabLab')"
                                />
                            </li>
                            <li
                                @click="showModal('Mathematik')"
                                v-b-tooltip.hover.top="'Mathematik'"
                            >
                                <img
                                    src="../assets/img/mathematik.jpg"
                                    :class="checkWorkshop('Mathematik')"
                                />
                            </li>
                            <li
                                @click="showModal('Ozobot')"
                                v-b-tooltip.hover.top="'Ozobot'"
                            >
                                <img
                                    src="../assets/img/ozobot.jpg"
                                    :class="checkWorkshop('Ozobot')"
                                />
                            </li>
                            <li
                                @click="showModal('Physik I')"
                                v-b-tooltip.hover.top="'Physik I'"
                            >
                                <img
                                    src="../assets/img/physik_eins.jpg"
                                    :class="checkWorkshop('Physik I')"
                                />
                            </li>
                            <li
                                @click="showModal('Physik II')"
                                v-b-tooltip.hover.top="'Physik II'"
                            >
                                <img
                                    src="../assets/img/physik_zwei.png"
                                    :class="checkWorkshop('Physik II')"
                                />
                            </li>
                            <li
                                @click="showModal('Pocket Code I')"
                                v-b-tooltip.hover.top="'Pocket Code I'"
                            >
                                <img
                                    src="../assets/img/pocketcode_eins.jpg"
                                    :class="checkWorkshop('Pocket Code I')"
                                />
                            </li>
                            <li
                                @click="showModal('Pocket Code II')"
                                v-b-tooltip.hover.top="'Pocket Code II'"
                            >
                                <img
                                    src="../assets/img/pocketcode_zwei.png"
                                    :class="checkWorkshop('Pocket Code II')"
                                />
                            </li>
                            <li
                                @click="showModal('Safe Surfen')"
                                v-b-tooltip.hover.top="'Safe Surfen'"
                            >
                                <img
                                    src="../assets/img/safesurfen.jpg"
                                    :class="checkWorkshop('Safe Surfen')"
                                />
                            </li>
                            <li
                                @click="showModal('Science')"
                                v-b-tooltip.hover.top="'Science'"
                            >
                                <img
                                    src="../assets/img/science.jpg"
                                    :class="checkWorkshop('Science')"
                                />
                            </li>
                            <li
                                @click="showModal('Chemie')"
                                v-b-tooltip.hover.top="'Chemie'"
                            >
                                <img
                                    src="../assets/img/chemie.jpg"
                                    :class="checkWorkshop('Chemie')"
                                />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="bv-modal-example" hide-footer>
            <template #modal-title>
                Bitte geben Sie Ihren Workshop Code ein
            </template>
            <div class="d-block text-center">
                <b-form @submit="onSubmit">
                    <b-form-group
                        id="input-group-1"
                        label="Workshop Code:"
                        label-for="input-1"
                    >
                        <b-form-input
                            id="input-1"
                            v-model="form.workshopkey"
                            type="text"
                            placeholder="Workshop Code eingeben"
                            required
                        ></b-form-input>
                    </b-form-group>
                    <b-alert
                        v-show="!checkWorkshopBoolean(this.form.section)"
                        show
                        variant="warning"
                        >Für diesen Bereich sind keine Workshops
                        vorhanden.</b-alert
                    >
                    <b-button type="submit" variant="danger">
                        <b-icon
                            v-if="loading"
                            class="button-loading-icon"
                            icon="three-dots"
                            animation="cylon"
                            font-scale="1"
                        ></b-icon>
                        <span v-if="!loading">Let’s TU it!</span></b-button
                    >
                    <b-form-group>
                        <div class="alert-container">
                            <b-alert v-if="error" show variant="danger">{{
                                $t(error)
                            }}</b-alert>
                        </div>
                    </b-form-group>
                </b-form>
            </div>
        </b-modal>
    </b-container>
</template>

<script>
import auth from "../auth/auth.helpers";

export default {
    data() {
        return {
            form: {
                workshopkey: "",
                section: ""
            },
            error: false,
            loading: false,
            workshops: []
        };
    },
    mounted() {
        this.loadOverview();
    },
    methods: {
        checkWorkshop(area) {
            let style = "";
            if (this.workshops) {
                let find = this.workshops.findIndex(x => x.section == area);
                if (find >= 0) {
                    style = "hasWorkshops";
                }
            }
            return style;
        },
        checkWorkshopBoolean(area) {
            if (this.workshops) {
                return this.workshops.findIndex(x => x.section === area) !== -1;
            }
        },
        loadOverview() {
            this.$store.dispatch("workshop/get", "overview").then(val => {
                this.workshops = val.workshops;
            });
        },
        showModal(area) {
            this.form.workshopkey = "";
            this.form.section = area;
            this.error = null;
            this.$bvModal.show("bv-modal-example");
        },
        onSubmit(event) {
            this.$store.dispatch(
                "workshop/setWorkshopKey",
                this.form.workshopkey
            );
            event.preventDefault();
            this.loading = true;
            auth.login(this.form, "Quiz", callback => {
                if (callback !== true) {
                    this.error = callback.message;
                }
                this.loading = false;
            });
        }
    }
};
</script>

<style scoped lang="scss">
/// @param {Integer} $item-count - Number of items on the circle
/// @param {Length} $circle-size - Large circle size
/// @param {Length} $item-size - Single item size
@mixin on-circle($item-count, $circle-size, $item-size) {
    position: relative;
    width: $circle-size;
    height: $circle-size;
    border-radius: 50%;
    padding: 0;
    list-style: none;

    > * {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -($item-size / 2);
        width: $item-size;
        height: $item-size;

        $angle: (360 / $item-count);
        $rot: 0;

        @for $i from 1 through $item-count {
            &:nth-of-type(#{$i}) {
                transform: rotate($rot * 1deg)
                    translate($circle-size / 2)
                    rotate($rot * -1deg);
            }

            $rot: $rot + $angle;
        }
    }
}

.circle-container {
    @include on-circle($item-count: 13, $circle-size: 25rem, $item-size: 5rem);
    margin: 5em auto 0;
    border: solid 5px tomato;

    img {
        cursor: not-allowed;
        display: block;
        max-width: 100%;
        border-radius: 50%;
        border: solid 5px grey;
        transition: 0.15s;
    }

    img.hasWorkshops {
        cursor: pointer;
        display: block;
        max-width: 100%;
        border-radius: 50%;
        filter: grayscale(0%);
        border: solid 5px tomato;
        transition: 0.15s;
        background: rgba(255, 82, 82, 1);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
        animation: pulse-red 2s infinite;
    }
}

@keyframes pulse-red {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
    }

    70% {
        transform: scale(1.2);
        box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
}

.logo {
    display: block;
    width: 100%;
    position: absolute;
    margin-top: 40%;
}

.headerlogo {
    text-align: center;
}

.headerimage {
    width: 5%;
}

@media only screen and (max-width: 600px) {
    .circle-container {
        @include on-circle(
            $item-count: 13,
            $circle-size: 19rem,
            $item-size: 4rem
        );
        margin: 5em auto 0;
        border: solid 5px tomato;

        img {
            display: block;
            max-width: 100%;
            border-radius: 50%;
            border: solid 5px grey;
            transition: 0.15s;

            &:hover {
                filter: grayscale(0);
            }
        }
    }

    .logo {
        margin-top: 35%;
    }
}
</style>
